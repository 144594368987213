import INPUT_SIZES from './constants';
export const getDisabledProps = (theme, isDisabled) => (isDisabled ? ({
    '& .MuiInputBase-root': {
        backgroundColor: `${theme.customColors.input.backgroundDisabled}`,
    },
    '& .MuiInputBase-input.Mui-disabled': {
        WebkitTextFillColor: 'inherit',
    },
}) : {});
export const defineFocusedColor = (theme, status) => {
    if (status === 'success') {
        return {
            boxShadow: '0px 0px 0px 3px rgba(48, 164, 108, 0.20)',
            borderColor: theme.palette.success.main,
        };
    }
    if (status === 'error') {
        return {
            boxShadow: '0px 0px 0px 3px rgba(229, 72, 77, 0.20)',
            border: '',
        };
    }
    return {
        boxShadow: '0px 0px 0px 3px rgba(62, 99, 221, 0.20)',
    };
};
export const defineInputPaddings = ({ hasStartAdornment, hasEndAdornment, size, }) => {
    var _a, _b, _c, _d;
    if (hasStartAdornment && hasEndAdornment) {
        return (_a = INPUT_SIZES[size]) === null || _a === void 0 ? void 0 : _a.inputPaddingWithAdornments;
    }
    if (hasStartAdornment) {
        return (_b = INPUT_SIZES[size]) === null || _b === void 0 ? void 0 : _b.inputPaddingStartAdornment;
    }
    if (hasEndAdornment) {
        return (_c = INPUT_SIZES[size]) === null || _c === void 0 ? void 0 : _c.inputPaddingEndAdornment;
    }
    return (_d = INPUT_SIZES[size]) === null || _d === void 0 ? void 0 : _d.inputPadding;
};
